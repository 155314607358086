import { memo, useEffect, useRef, useState } from "react";

function DropStars({ children, theme = "yellow", direction = "top", isShow = false, style }) {
  const [starsTop, setStarsTop] = useState([0, 60, 270, 100, 200]);
  const ref = useRef();

  useEffect(() => {
    const container = ref.current;
    const video = container.querySelector("video");
    video.playbackRate = 0.8;
  }, []);

  return (
    <div ref={ref} className={`position-relative _bg-stars ${isShow ? "opacity-100" : "opacity-0"} ${style}`}>
      <video src="/wave.webm" autoPlay loop playsInline className={`position-absolute top-0 start-0 w-100 h-100 _bg-wave-${direction}-${theme}`}></video>
      <div className="position-absolute top-0 start-0 w-100 h-100 _overlay" />
      <div className="position-absolute top-0 start-0 w-100 h-100 _overlay-fade" />
      {children}
    </div>
  );
}

export default memo(DropStars);
