import { Fragment, useContext, useState } from "react";
import { appContext } from "../context/appContext";
import request from "../helper/request";

export default function Page10() {
  const {
    value: { data },
  } = useContext(appContext);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isGoing, setIsGoing] = useState(false);
  const [hasError, setHasError] = useState(null);

  const handleButtonClicked = () => {
    const answerKeys = Object.keys(data);

    if (answerKeys.length === 0) {
      setHasError(
        "Ohh, hình như bạn chưa trả lời câu hỏi nào cả, hãy cho chúng tôi biết những ứng cử viên xứng đáng nhé 🥰"
      );
      return;
    }

    const errorMessages = [
      "Hình như bạn chưa trả lời hết các câu hỏi. Hãy quay lại đây sau khi hoàn thành các câu hỏi còn thiếu dưới đây nhé 😊",
    ];
    const isKeysValid = [
      {
        key: "name",
        errorMessage: "Bạn chưa điền tên của mình",
      },
      {
        key: "team",
        errorMessage: "Bạn chưa chọn team của mình",
      },
      {
        key: "bestMember",
        errorMessage: "Hạng mục The Best Member",
      },
      {
        key: "theRookie",
        errorMessage: "Hạng mục The Rookie",
      },
      {
        key: "perfectDuo",
        errorMessage: "Hạng mục The Perfect Duo",
      },
      {
        key: "bestEvent",
        errorMessage: "Hạng mục The Best Event",
      },
    ].reduce((isValid, condition) => {
      const isAnswerKeyValid = answerKeys.includes(condition.key);
      if (!isAnswerKeyValid) errorMessages.push(condition.errorMessage);

      isValid = isValid && isAnswerKeyValid;
      return isValid;
    }, true);

    if (!isKeysValid) {
      setHasError(errorMessages.join("<br />"));
      return;
    }

    console.log(data);

    setHasError(null);
    data.isGoing = isGoing;

    request
      .post("answers.json", data)
      .then(() => setIsSubmitted(true))
      .catch((reason) => alert(`Lỗi, hãy thử lại`));

    // alert(JSON.stringify(data));
    // setIsSubmitted(true);
  };

  return (
    <div className="row justify-content-center align-items-center h-100">
      <div className="col">
        <div className="row align-items-center">
          <div className="col-md-6 col-12 mb-5">
            <img
              src="main.png"
              className="img-fluid animate__animated animate__fadeIn"
            />
          </div>
          <div className="col-md-6 col-12">
            <div className="row g-3 justify-content-md-start justify-content-center">
              <div className="col-md col-auto">
                <h4 className="text-yellow animate__animated animate__fadeIn">
                  &ldquo; Cảm ơn bạn đã tham gia bình chọn! &rdquo;
                </h4>
                <h5 className="text-yellow opacity-50 mt-4 animate__animated animate__fadeIn">
                  Kết quả của Round 2 sẽ được công bố ở sự kiện Hugo Award 2023.
                </h5>
                <h4 className="text-yellow-light mt-4 animate__animated animate__fadeIn animate__slow">
                  Bạn sẽ tham gia sự kiện tổng kết cuối năm Hugo Award 2023 cùng
                  Hugo chứ?
                </h4>
                <div className="row mt-3 animate__animated animate__fadeIn animate__slow">
                  <div className="col-auto">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isGoing"
                        checked={isGoing}
                        onClick={() => setIsGoing(!isGoing)}
                      />
                      <label
                        className="form-check-label text-yellow-light h5"
                        for="isGoing"
                      >
                        Tất nhiên rồi
                      </label>
                    </div>
                  </div>
                </div>
                {isGoing && (
                  <Fragment>
                    <div className="row mt-3 g-2 animate__animated animate__fadeInUp">
                      <div className="col-auto">
                        <i className="bi-alarm text-yellow"></i>
                      </div>
                      <div className="col">
                        <h5 className="text-yellow-light">
                          Thời gian: 17h30, Chủ Nhật, ngày 30 tháng 7 năm 2023.
                        </h5>
                      </div>
                    </div>

                    <div className="row g-2 animate__animated animate__fadeInUp animate__slow">
                      <div className="col-auto">
                        <i className="bi-geo-alt text-yellow"></i>
                      </div>
                      <div className="col">
                        <h5 className="text-yellow-light">
                          Địa điểm: Tầng 2 Angel Coffee, 87 Quang Trung, Hải
                          Châu, Đà Nẵng.
                        </h5>
                      </div>
                    </div>
                  </Fragment>
                )}
                <div className="row justify-content-md-start justify-content-center mt-5">
                  <div className="col-auto">
                    <button
                      className="btn rounded-0 btn-lg btn-yellow animate__animated animate__fadeInUp"
                      onClick={handleButtonClicked}
                      disabled={isSubmitted}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {hasError && (
                  <div className="row justify-content-md-start justify-content-center mt-3 animate__animated animate__fadeIn">
                    <div className="col-auto">
                      <h5
                        className="text-yellow text-opacity-50"
                        dangerouslySetInnerHTML={{
                          __html: hasError,
                        }}
                      />
                    </div>
                  </div>
                )}
                {isSubmitted && (
                  <div className="row justify-content-md-start justify-content-center mt-3 animate__animated animate__fadeIn">
                    <div className="col-auto">
                      <h5 className="text-yellow text-opacity-50">
                        Câu trả lời của bạn đã được lưu.
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
