const BASE_PATH = "images";

export const teams = {
  Banana: `${BASE_PATH}/logos/Banana.png`,
  "Heroes Company": `${BASE_PATH}/logos/Heroes.png`,
  Niffler: `${BASE_PATH}/logos/Niffler.png`,
  "Power Rangers": `${BASE_PATH}/logos/Power.png`,
};

export const bestMembers = {
  "Huỳnh Kim Hoàng": `${BASE_PATH}/SOLO/Best Member/Hoang.jpg`,
  "Nguyễn Đức Quang": `${BASE_PATH}/SOLO/Best Member/Quang.jpg`,
  "Huỳnh Hoàng Thiện Kim": `${BASE_PATH}/SOLO/Best Member/Kim.jpg`,
  "Trần Anh Khoa": `${BASE_PATH}/SOLO/Best Member/Khoa.jpg`,
  "Trần Nguyên Khánh": `${BASE_PATH}/SOLO/Best Member/Khanh.jpg`,
};

export const bestEvents = {
  "Hugo Camping 2023": `${BASE_PATH}/SOLO/Best Event/CAMPING.jpg`,
  "Hugo Christmas 2022": `${BASE_PATH}/SOLO/Best Event/CHRISTMAS 2022.jpg`,
  "My Tet 2023: REUNION": `${BASE_PATH}/SOLO/Best Event/MY TET.jpg`,
  "Welcome Newbies 2023": `${BASE_PATH}/SOLO/Best Event/WELCOME NEWBIE.jpg`,
  "New World New Thang 2": `${BASE_PATH}/SOLO/Best Event/NEWWORLD NEW THANG 2.jpg`,
};

export const thePerfectDuos = {
  "Hoàng Văn Thắng - Đặng Bảo Chiến": `${BASE_PATH}/SOLO/The Perfect Duo/Chien Thang.jpg`,
  "Nguyễn Ngọc Tường Vy - Ngô Nguyễn Huy Anh": `${BASE_PATH}/SOLO/The Perfect Duo/Vy Anh.jpg`,
  "Lê Quang Nhật - Mai Trâm Anh": `${BASE_PATH}/SOLO/The Perfect Duo/Nhat Anh.jpg`,
  "Phùng Ngọc Uyên - Trần Anh Khoa": `${BASE_PATH}/SOLO/The Perfect Duo/Uyen Khoa.jpg`,
  "Nguyễn Quang Trường Phước - Nguyễn Lê Hồng Giang": `${BASE_PATH}/SOLO/The Perfect Duo/Giang Phuoc.jpg`,
};

export const theRookies = {
  "Ngô Thị Mỵ": `${BASE_PATH}/SOLO/The Rookie/my.jpg`,
  "Trần Thị Thu Thủy": `${BASE_PATH}/SOLO/The Rookie/thuy.jpg`,
  "Nguyễn Ngọc Minh Tâm": `${BASE_PATH}/SOLO/The Rookie/tam.jpg`,
  "Trần Tuấn Võ": `${BASE_PATH}/SOLO/The Rookie/vo.jpg`,
  "Dương Thị Hoài": `${BASE_PATH}/SOLO/The Rookie/hoai.jpg`,
};
