import { useContext, useEffect, useRef, useState } from "react";
import DropStars from "../components/DropStars";
import Logo from "../components/Logo";
import MultipleChoices from "../components/MultipleChoices";
import appActions from "../context/appActions";
import { appContext } from "../context/appContext";
import { bestMembers } from "../services/posters";

export default function Page5() {
  const {
    value: { data },
    dispatch,
  } = useContext(appContext);

  const choices = [
    {
      value: "Huỳnh Kim Hoàng",
      theme: "blue",
    },
    {
      value: "Nguyễn Đức Quang",
      theme: "blue",
    },
    {
      value: "Huỳnh Hoàng Thiện Kim",
      theme: "red",
    },
    {
      value: "Trần Anh Khoa",
      theme: "blue",
    },
    {
      value: "Trần Nguyên Khánh",
      theme: "blue",
    },
  ];

  const choiceContainerRef = useRef();
  const [currentChoices, setCurrentChoices] = useState(data.bestMember || []);
  const handleChoiceChanged = (newChoices) => {
    const choiceContainer = choiceContainerRef.current;
    const images = choiceContainer.querySelectorAll("img");

    images.forEach((image, index) => {
      const currentChoice = currentChoices[index];
      const newChoice = newChoices[index];

      if (currentChoice === newChoice) return;

      const animationName = image.style.animationName;

      image.style.animationPlayState = "paused";
      image.style.animationName = "none";

      void image.offsetWidth; // Trigger reflow

      image.style.animationPlayState = "running";
      image.style.animationName = animationName;
    });

    setCurrentChoices(newChoices);
  };

  const handleButtonClicked = () => {
    dispatch({
      type: appActions.CHANGE_PAGE,
      payload: 5,
    });

    dispatch({
      type: appActions.CHANGE_ANSWER,
      payload: {
        bestMember: currentChoices,
      },
    });
  };

  return (
    <div className="row flex-column justify-content-center align-items-center h-100">
      <div className="col-auto">
        <Logo
          style="animate__animated animate__fadeIn"
          icon="logo_main.png"
          width="300px"
        />
      </div>
      <div className="col">
        <div className="row flex-column justify-content-center h-100">
          <div className="col">
            <div className="row h-100">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="row flex-column gap-4 h-100">
                  <div className="col-auto order-2 order-md-1">
                    <div className="row">
                      <div className="col-auto">
                        <h4 className="text-yellow animate__animated animate__fadeInUp">
                          3
                        </h4>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-auto">
                            <h4 className="text-yellow animate__animated animate__fadeInUp">
                              Theo bạn, Hugoer nào đã hoạt động năng nổ nhất
                              trong nhiệm kỳ vừa qua?
                            </h4>
                            <h5 className="text-yellow fst-italic text-opacity-50 animate__animated animate__fadeIn animate__slow">
                              Chọn tối đa 2 trong 5 cái tên dưới đây
                            </h5>
                          </div>
                        </div>

                        <MultipleChoices
                          choices={choices}
                          onChoicesChange={handleChoiceChanged}
                          currentChoices={currentChoices}
                          maxChoices={2}
                        />

                        <div className="row mt-3">
                          <div className="col-auto">
                            <button
                              className="btn rounded-0 btn-lg btn-yellow animate__animated animate__fadeIn animate__delay-1s"
                              onClick={handleButtonClicked}
                              disabled={
                                !currentChoices || currentChoices.length != 2
                              }
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto order-1 order-md-2">
                    <div className="row flex-column align-items-center justify-content-end h-100">
                      <Logo
                        style="animate__animated animate__fadeIn"
                        icon="best-member.svg"
                        title="The Best Member"
                        width="100px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12">
                <div
                  ref={choiceContainerRef}
                  className="row pt-5 pt-md-0 h-100"
                >
                  <div className="col">
                    <div className="row flex-column h-100">
                      <div className="col">
                        <DropStars
                          style="w-100 h-100"
                          theme={currentChoices[0]?.theme}
                          direction="top"
                          isShow={currentChoices[0]}
                        >
                          <div className="row flex-column justify-content-end h-100">
                            <div className="col-auto">
                              {currentChoices[0] && (
                                <img
                                  src={bestMembers[currentChoices[0].value]}
                                  className={`img-fluid border border-${currentChoices[0].theme} _glow-${currentChoices[0].theme} animate__animated animate__fadeInUp`}
                                />
                              )}
                            </div>
                          </div>
                        </DropStars>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row flex-column h-100">
                      <div className="col">
                        <DropStars
                          style="w-100 h-100"
                          theme={currentChoices[1]?.theme}
                          direction="bottom"
                          isShow={currentChoices[1]}
                        >
                          <div className="row flex-column justify-content-start h-100">
                            <div className="col-auto">
                              {currentChoices[1] && (
                                <img
                                  src={bestMembers[currentChoices[1].value]}
                                  className={`img-fluid border border-${currentChoices[1].theme} _glow-${currentChoices[1].theme} animate__animated animate__fadeInDown`}
                                />
                              )}
                            </div>
                          </div>
                        </DropStars>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
