import { useContext, useRef, useState } from "react";
import DropStars from "../components/DropStars";
import Logo from "../components/Logo";
import MultipleChoices from "../components/MultipleChoices";
import appActions from "../context/appActions";
import { appContext } from "../context/appContext";
import { teams } from "../services/posters";

export default function Page4() {
  const {
    value: { data },
    dispatch,
  } = useContext(appContext);

  const choices = [
    {
      value: "Banana",
      theme: "yellow",
      slogan: "We Come In Bunch",
    },
    {
      value: "Heroes Company",
      theme: "blue",
      slogan: "Stronger Together",
    },
    {
      value: "Power Rangers",
      theme: "red",
      slogan: "Justice For All",
    },
    {
      value: "Niffler",
      theme: "purple",
      slogan: "Friends Forever",
    },
  ];

  const choiceContainerRef = useRef();
  const [currentChoices, setCurrentChoices] = useState(data.team ? [data.team] : []);
  const handleChoiceChanged = (newChoices) => {
    const choiceContainer = choiceContainerRef.current;
    const images = choiceContainer.querySelectorAll("img");

    images.forEach((image, index) => {
      const currentChoice = currentChoices[index];
      const newChoice = newChoices[index];

      if (currentChoice === newChoice) return;

      const animationName = image.style.animationName;

      image.style.animationPlayState = "paused";
      image.style.animationName = "none";

      void image.offsetWidth; // Trigger reflow

      image.style.animationPlayState = "running";
      image.style.animationName = animationName;
    });

    setCurrentChoices(newChoices);
  };

  const handleButtonClicked = () => {
    dispatch({
      type: appActions.CHANGE_PAGE,
      payload: 4,
    });

    dispatch({
      type: appActions.CHANGE_ANSWER,
      payload: {
        team: currentChoices[0],
      },
    });
  };

  return (
    <div className="row flex-column justify-content-center align-items-center h-100">
      <div className="col-auto">
        <Logo style="animate__animated animate__fadeIn" icon="logo_main.png" width="300px" />
      </div>
      <div className="col">
        <div className="row flex-column justify-content-center h-100">
          <div className="col">
            <div className="row h-100">
              <div className="col-lg-4 col-md-6 col-10">
                <div className="row flex-column h-100">
                  <div className="col-auto">
                    <div className="row">
                      <div className="col-auto">
                        <h4 className="text-yellow animate__animated animate__fadeInUp">2</h4>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-auto">
                            <h4 className="text-yellow animate__animated animate__fadeInUp">Bạn đến từ?</h4>
                          </div>
                        </div>

                        <MultipleChoices choices={choices} onChoicesChange={handleChoiceChanged} currentChoices={currentChoices[0] ? [currentChoices[0]] : []} maxChoices={1} />

                        <div className="row mt-3 justify-content-end">
                          <div className="col-auto">
                            <button
                              className="btn rounded-0 btn-lg btn-yellow animate__animated animate__fadeIn animate__delay-1s"
                              onClick={handleButtonClicked}
                              disabled={!currentChoices[0]}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row flex-column justify-content-end h-100">
                      <div className="col-auto">
                        {currentChoices[0] && (
                          <h1 className={`text-${currentChoices[0].theme} fw-bold mb-0 animate__animated animate__fadeIn`} style={{ fontSize: "4.5rem" }}>
                            {currentChoices[0].slogan}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12">
                <div ref={choiceContainerRef} className="row h-100">
                  <div className="col">
                    <div className="row flex-column h-100">
                      <div className="col">
                        <DropStars style="h-100  animate__animated animate__fadeIn" theme={currentChoices[0]?.theme} direction="top" isShow={currentChoices[0]}>
                          <div className="row flex-column justify-content-center align-items-center h-100">
                            <div className="col-auto">
                              {currentChoices[0] && (
                                <img
                                  src={teams[currentChoices[0].value]}
                                  width="350px"
                                  className={`rounded-circle img-fluid _glow-${currentChoices[0]?.theme} animate__animated animate__fadeInDown`}
                                />
                              )}
                            </div>
                          </div>
                        </DropStars>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
